<template>
  <v-card>
    <v-card-title class="pa-1">
      <v-toolbar dense small elevation="1">
        <v-toolbar-title>
          <span>{{ label }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon color="success" @click="addNewItem">
          <v-icon small>mdi-plus-box</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="addFromCipboard">
          <v-icon small>mdi-clipboard-arrow-down</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(h, i) in normalizedHeaders" :key="`inline-header-${i}`">
                {{ h.title }}
              </th>
              <th>
                {{ actionsColumnString }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in internalValue"
              :key="`inline-item-${i}`"
            >
              <td v-for="(h, hi) in headers" :key="`inline-col-${i}-${hi}`">
                <component
                  :is="h.component"
                  v-bind="h.getProps(item)"
                  @input="onUpdateItem(item, i, h, $event)"
                />
              </td>
              <td class="d-flex flex-row align-center justify-center">
                <confirm-remove-dialog @remove="onRemove(i)">
                  <template v-slot:activator="{on}">
                    <v-btn small icon color="red" v-on="on">
                      <v-icon small>mdi-database-remove</v-icon>
                    </v-btn>
                  </template>
                </confirm-remove-dialog>
                <v-btn small icon color="primary" @click="onCopyItem(item)">
                  <v-icon small>mdi-clipboard-arrow-up</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import { makeRandomId } from '@peynman/press-vue-core/utils/helpers'
  import ConfirmRemoveDialog from '@peynman/press-vue-admin/components/Inputs/ConfirmDialog/ConfirmRemoveDialog.vue'

  export default {
    name: 'InlineEditableTable',
    components: {
      ConfirmRemoveDialog,
    },
    props: {
      label: String,
      headers: Array,
      value: Array,
      actionsColumnString: {
        default: 'Actions',
        type: String,
      },
    },
    data: vm => ({
      internalValue: vm.value ?? [],
    }),
    computed: {
      normalizedHeaders () {
        return this.headers.map(h => ({
          component: 'VTextField',
          getProps (item) {
            return {
              value: item[h.key],
              ...h.props,
            }
          },
          ...h,
        }))
      },
    },
    watch: {
      value () {
        this.internalValue = this.value ?? []
      },
    },
    methods: {
      addFromCipboard () {
        navigator.clipboard.readText()
          .then(text => {
            try {
              const json = JSON.parse(text)
              this.internalValue.push({
                ...json,
                __id: makeRandomId(),
              })
              this.$emit('input', this.internalValue)
            } catch (e) {
              console.error('Failed to convert clipboard contents: ', e)
            }
          })
          .catch(err => {
            console.error('Failed to read clipboard contents: ', err)
          })
      },
      onCopyItem (item) {
        navigator.clipboard.writeText(JSON.stringify(item)).then(function () {
          console.log('Async: Copying to clipboard was successful!')
        }, function (err) {
          console.error('Async: Could not copy text: ', err)
        })
      },
      onUpdateItem (item, index, header, value) {
        this.$set(this.internalValue[index], header.key, value)
        this.$emit('input', this.internalValue)
      },
      addNewItem () {
        if (!Array.isArray(this.internalValue)) {
          this.internalValue = []
        }
        this.internalValue.push({
          __id: makeRandomId(),
        })
        this.$emit('input', this.internalValue)
      },
      onRemove (i) {
        this.internalValue.splice(i, 1)
        this.$emit('input', this.internalValue)
      },
    },
  }
</script>
